import React from 'react'
import Schoolar from '../res/schoolar.svg'
import SchoolarMobile from '../res/schoolar-md.svg'

const appLink = process.env.REACT_APP_BASE_URL

export default function HeaderIcon() {
  return (
    <div>
      <a href={appLink} target={'_blank'} rel='noreferrer'>
        <img src={Schoolar} alt={'Schoolar Logo'} className='schoolarLogo' />
        <img
          src={SchoolarMobile}
          alt={'Schoolar Logo Mobile Display'}
          className='schoolarLogo-md'
        />
      </a>
    </div>
  )
}
