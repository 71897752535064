import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAu470486_eUt4qjlhYCsSmNc7w0z9wdNM",
    authDomain: "schoolar-app-8c77f.firebaseapp.com",
    projectId: "schoolar-app-8c77f",
    storageBucket: "schoolar-app-8c77f.appspot.com",
    messagingSenderId: "60191732916",
    appId: "1:60191732916:web:cb18250bfca82e5a8a93e1",
    measurementId: "G-6FK9WQCLPM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const FirebaseContext = createContext(app)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <FirebaseContext.Provider value={app}>
                <App />
            </FirebaseContext.Provider>
        </BrowserRouter>
    </React.StrictMode>
);

