import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Hero from './hero/Hero'

function App() {
  return (
    <Routes>
      <Route exact path={'*'} element={<Hero />} />
    </Routes>
  )
}

export default App
