import React, { useContext, useEffect } from 'react'
import styles from './Hero.module.css'
import HeaderIcon from '../components/HeaderIcon'
import { FirebaseContext } from '../index'
import { getAnalytics, logEvent } from 'firebase/analytics'

function Hero() {
  const app = useContext(FirebaseContext)
  const analytics = getAnalytics(app)

  useEffect(() => {
    logEvent(analytics, 'Sunset_page_visited')
  }, [analytics])

  useEffect(() => {
    setTimeout(() => {
      document.location.href = "https://calciumapps.notion.site/Schoolar-App-Discontinued-c03a264133204266b955fa940c014677"
    }, 2500)
  }, [])

  return (
    <main className={styles.main}>
      <div className={styles.wrapper}>
        <HeaderIcon />
        <p style={{marginTop: 50}}>Redirecting you...</p>
      </div>
    </main>
  )
}

export default Hero
